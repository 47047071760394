const people = [
  {
    name: "John Cheslik",
    role: "Chief Executive Officer",
    linkedinUrl: "https://www.linkedin.com/in/john-cheslik-2a46121/",
  },
  {
    name: "Jim Easterling",
    role: "Chief Operations Officer",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735662/IMG-9529_eplfqm.jpg",
    linkedinUrl: "https://www.linkedin.com/in/jim-easterling-29820550/",
  },
  {
    name: "Ryan Forrester",
    role: "Chief Technology Officer",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735656/IMG-9532_radwz5.jpg",
    linkedinUrl: "https://www.linkedin.com/in/ryan-forrester-71abb1bb/",
  },
  {
    name: "Madi Easterling",
    role: "Marketing Manager",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735297/IMG-9548_u44mpq.jpg",
    linkedinUrl: "https://www.linkedin.com/in/madi-easterling-2033b3177/",
  },
  {
    name: "Kenneth Holley",
    role: "Director of Controls",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1678259214/Kenneth_s_Profile_bz35k5.jpg",
  },
  {
    name: "Scott Forrester",
    role: "Senior Vice President of Business Development",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735660/IMG-9533_wa6s3h.jpg",
    linkedinUrl: "https://www.linkedin.com/in/scott-forrester-b80b521b/",
  },
  {
    name: "Jeff Littlejohn",
    role: "Executive Vice President of Regulatory Affairs",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1677736140/Jeff_L_Web_HS_kmpjkx.jpg",
    linkedinUrl: "https://www.linkedin.com/in/jeff-littlejohn-p-e-405a4914/",
  },
  {
    name: "Greg Harris",
    role: "Regional Sales Executive - Florida",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1677735662/IMG-9528_tdmnzw.jpg",
    linkedinUrl: "https://www.linkedin.com/in/greg-harris-44901671/",
  },
  {
    name: "Hassan Tariq",
    role: "Director of Software Engineering",
    imageUrl:
      "https://res.cloudinary.com/dm17kkmbh/image/upload/v1678253492/DSC01571_iivikl.jpg",
    linkedinUrl: "https://www.linkedin.com/in/hassan-tariq-1382b112b/",
  },
  {
    name: "John Ford",
    role: "Regional Sales Executive - Georgia",
    imageUrl: "/images/img-john-ford.jpg",
  },
  {
    name: "Sunil Mehta",
    role: "Director of Operations - Florida",
    linkedinUrl: "https://www.linkedin.com/in/sunil-mehta-5087166/",
  },
  // More people...
];

export default function About() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our team
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We’re a dynamic group of individuals who are passionate about what
            we do and dedicated to delivering the best results for our clients.
          </p>
        </div>
        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {people.map((person) => (
            <li key={person.name}>
              {person.imageUrl ? (
                <img
                  className="aspect-[4/5] w-3/4 rounded-2xl object-cover"
                  src={person.imageUrl}
                  alt=""
                />
              ) : (
                <div className="aspect-[4/5] w-3/4 rounded-2xl object-cover" />
              )}
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-base leading-7 text-gray-600">{person.role}</p>
              <ul className="mt-6 flex gap-x-6">
                {person.linkedinUrl && (
                  <a
                    href={person.linkedinUrl}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
